@use './variables' as *;
@use './mixins' as *;

:root {
  @each $name, $value in $color-style {
    --clr-#{$name}: #{$value};
  }

  @each $name, $value in $shadow-style {
    --sd-#{$name}: #{$value};
  }

  --gap: 1em;
}

html,
body {
  font-family: -apple-system, 'PingFangSC-Regular', 'Roboto', arial, 'Microsoft YaHei',
    'NotoSansSC-regular', sans-serif;
  margin: 0;
  background-color: var(--clr-white98);

  @include pad {
    overflow: hidden;
  }
}
