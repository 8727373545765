@use 'sass:math';
@use '../../variables' as *;

@mixin phone {
  @content;
}

@mixin pad {
  @media screen and (min-width: map-get($breakpoints, sm)) {
    @content;
  }
}

@mixin pad-rotate {
  @media screen and (min-width: map-get($breakpoints, lg)) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: map-get($breakpoints, sl)) {
    @content;
  }
}

@mixin monitor {
  @media screen and (min-width: map-get($breakpoints, xl)) {
    @content;
  }
}

@mixin oversize {
  @media screen and (min-width: map-get($breakpoints, xxl)) {
    @content;
  }
}
